import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const RebalanceNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="overview">
      <Nav.Item>
        <NavLink to="overview" className="nav-link">
          Overview
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="trades" className="nav-link">
          Trades
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="household-details" className="nav-link">
          Household Details
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="model-trading" className="nav-link">
          Model / Trading
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="cash-billing" className="nav-link">
          Cash & Billing
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="explain" className="nav-link">
          Explain
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default RebalanceNav;
