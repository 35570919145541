import { useContext } from "react";
import React from "react";
import { useState } from "react";
import { Collapse, Container, Button, Offcanvas } from "react-bootstrap";
import { Col, Row, Table } from "react-bootstrap";
import Loading from "../Loading";
import { RebalanceContext } from "./RebalanceInfo";
import { useAuthenticatedFetch } from "../lib/api";
import type { UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";

interface AccountSettings {
  account_type: string;
  tax_type: "tax_exempt" | "taxable";
  gains_budget_lt: number | null;
  gains_budget_st: number | null;
  wash_sale_treatment: "avoid_wash_sales";
}

interface Account {
  account_id: number;
  bridge_account_id: number;
  account_name: string;
  rebalance_type: "tolerance";
  is_rebalance_account: boolean;
  raise_spend_cash_amount: number | null;
  settings: AccountSettings;
  model_id: number;
  model: {
    primary: Record<string, number>;
    secondary: Record<string, Record<string, Record<string, number>>>;
  } | null;
  security_restrictions: Record<string, unknown>;
  cash_settings: {
    target_cash_type: "absolute";
    target_cash_weight: number | null;
    target_cash_dollar: number | null;
    minimum_cash_trigger_weight: number | null;
    minimum_cash_trigger_dollar: number | null;
    minimum_cash_trigger_condition: "AND";
    maximum_cash_trigger_weight: number | null;
    maximum_cash_trigger_dollar: number | null;
    maximum_cash_trigger_condition: "AND";
  };
  risk_score: number | null;
  balance: number | null;
  cash_balance: number | null;
  holdings: null;
}

interface SharedRebalanceData {
  accounts: Record<string, Account>;
}

interface AccountLinkProps {
  accountId: string | number;
  accountName: string | null;
}

const AccountLink: React.FC<AccountLinkProps> = ({
  accountId,
  accountName,
}) => {
  if (!accountId) return null;

  return (
    <>
      {" "}
      -{" "}
      <a
        href={`/clients/accounts/${accountId}/overview`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {accountName}
      </a>
    </>
  );
};

const PortfolioWithSecondaryRow = ({
  accountId,
  data,
  accountName,
}: {
  accountId: string;
  accountName: string;
  data: {
    primary: Record<string, number>;
    secondary: Record<string, Record<string, Record<string, unknown>>>;
  };
}) => (
  <Row>
    <Col md={6}>
      <div>
        <h5>
          Primary Securities
          <AccountLink accountId={accountId} accountName={accountName} />
        </h5>
        <Table>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Target Allocation</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data.primary).map(([symbol, allocation]) => (
              <tr key={symbol}>
                <td>{symbol}</td>
                <td>{allocation}</td>
              </tr>
            ))}
            <tr className="fw-bold">
              <td>Total</td>
              <td>
                {Object.values(data.primary).reduce(
                  (sum, allocation) => sum + allocation,
                  0,
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Col>
    <Col md={6}>
      <div>
        <h5>
          Security Groups
          {accountId && (
            <AccountLink accountId={accountId} accountName={accountName} />
          )}
        </h5>
        <Table>
          <thead>
            <tr>
              <th>Primary</th>
              <th>Secondary</th>
              <th>Treatment</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data.secondary).map(([symbol, mapping]) => {
              const [primarySymbol, treatment] = Object.entries(mapping)[0];
              const treatmentType = Object.keys(treatment)[0];
              return (
                <tr key={symbol}>
                  <td>{primarySymbol}</td>
                  <td>{symbol}</td>
                  <td>{treatmentType}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Col>
  </Row>
);

interface Holding {
  identifier: string;
  abs_value: number;
  abs_units: number;
}

const HoldingsCol = ({
  accountId,
  accountName,
  holdings,
}: {
  accountId: string;
  accountName: string;
  holdings: Holding[];
}) => (
  <Col md={6}>
    <h5>
      Starting Holdings
      <AccountLink accountId={accountId} accountName={accountName} />
    </h5>
    <Table>
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Value</th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        {holdings.map((holding) => (
          <tr key={holding.identifier}>
            <td>{holding.identifier}</td>
            <td>${holding.abs_value.toLocaleString()}</td>
            <td>{holding.abs_units}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Col>
);
const PortfolioCol = ({
  accountId,
  accountName,
  data,
}: {
  accountId: string;
  accountName: string;
  data: Record<string, number>;
}) => (
  <Col md={6}>
    <div>
      <h5>
        Target Portfolio
        {accountId && (
          <AccountLink accountId={accountId} accountName={accountName} />
        )}
      </h5>
      <Table>
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Target Allocation</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([symbol, allocation]) => (
            <tr key={symbol}>
              <td>{symbol}</td>
              <td>{allocation}</td>
            </tr>
          ))}
          <tr className="fw-bold">
            <td>Total</td>
            <td>
              {Object.values(data).reduce(
                (sum, allocation) => sum + allocation,
                0,
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  </Col>
);

const CategoryMappingsRow = ({
  categoryMapping,
}: {
  categoryMapping: Record<string, Record<string, number>>;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <h4 onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
            Category Mappings {open ? "▼" : "▶"}
          </h4>
        </Col>
      </Row>
      <Collapse in={open}>
        <div>
          <Table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Growth</th>
                <th>Defensive</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(categoryMapping).map(
                ([category, allocations]) => (
                  <tr key={category}>
                    <td>{category}</td>
                    <td>{allocations.growth}</td>
                    <td>{allocations.defensive}</td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
        </div>
      </Collapse>
    </>
  );
};

interface TargetPortfolioRowProps {
  explainData: {
    [accountId: string]: {
      starting_holdings: Holding[];
      initial_target_portfolios_by_account: Record<string, number>;
      restricted_securities_by_account: Record<string, Record<string, unknown>>;
    };
  };
  sharedData: SharedRebalanceData;
}

export const TargetPortfolioRow = ({
  explainData,
  sharedData,
}: TargetPortfolioRowProps) => (
  <>
    <Row>
      <h4>Target - Portfolios</h4>
    </Row>

    {Object.entries(explainData).map(([accountId, account]) => (
      <Container key={accountId}>
        <Row>
          {account.starting_holdings && (
            <HoldingsCol
              accountId={accountId}
              accountName={sharedData.accounts[accountId]?.account_name ?? ""}
              holdings={account.starting_holdings}
            />
          )}

          {account.initial_target_portfolios_by_account && (
            <PortfolioCol
              accountId={accountId}
              accountName={sharedData.accounts[accountId]?.account_name ?? ""}
              data={account.initial_target_portfolios_by_account}
            />
          )}
        </Row>
        <Row>
          <h5>Restricted Securities</h5>
          <div>
            {account.restricted_securities_by_account &&
            Object.keys(account.restricted_securities_by_account).length > 0
              ? "Implement restricted securities!!!"
              : "No restricted securities"}
          </div>
        </Row>
      </Container>
    ))}
  </>
);

interface TargetRiskAllocationRowProps {
  explainData: {
    account_target_risk_allocations: Record<string, Record<string, number>>;
    security_risk_allocations_by_account: Record<
      string,
      Record<string, Record<string, number | boolean>>
    >;
  };
  sharedData: SharedRebalanceData;
}

export const TargetRiskAllocationRow = ({
  explainData,
  sharedData,
}: TargetRiskAllocationRowProps) => (
  <>
    <Row>
      <h4>Target - Risk Allocations</h4>
    </Row>

    {Object.entries(explainData.account_target_risk_allocations).map(
      ([accountId, allocations]) => {
        const accountName = sharedData.accounts[accountId]?.account_name;
        return (
          <div key={accountId}>
            <Row>
              <Col md={6}>
                <h5>
                  Account Risk Allocations
                  {accountId && (
                    <AccountLink
                      accountId={accountId}
                      accountName={accountName}
                    />
                  )}
                </h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Risk Category</th>
                      <th>Allocation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(allocations).map(
                      ([category, allocation]) => (
                        <tr key={category}>
                          <td>{category}</td>
                          <td>{allocation}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col md={6}>
                <h5>
                  Security Risk Allocations
                  {accountId && (
                    <AccountLink
                      accountId={accountId}
                      accountName={accountName}
                    />
                  )}
                </h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Growth</th>
                      <th>Defensive</th>
                      <th>Cash</th>
                      <th>Unknown</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(
                      explainData.security_risk_allocations_by_account[
                        accountId
                      ],
                    ).map(([symbol, risks]) => (
                      <tr key={symbol}>
                        <td>{symbol}</td>
                        <td>{(risks.Growth as number) || 0}</td>
                        <td>{(risks.Defensive as number) || 0}</td>
                        <td>{(risks.Cash as number) || 0}</td>
                        <td>{(risks.Unknown as number) || 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        );
      },
    )}
  </>
);

interface RebalanceRequestExplainStepProps {
  explainData: {
    as_of_date: string;
    household_model: {
      primary: Record<string, number>;
      secondary: Record<string, Record<string, Record<string, unknown>>>;
    };
    accounts: Record<string, Account>;
    category_mapping?: Record<string, Record<string, number>>;
  };
}

export const RebalanceRequestExplainStep = ({
  explainData,
}: RebalanceRequestExplainStepProps) => (
  <>
    <Row>
      <h3>1. Rebalance Request</h3>
      <p>Initial data from the request and from the accounts and models.</p>
    </Row>

    <Container>
      {explainData.as_of_date && (
        <Row className="mb-3">
          <Col>
            As of Date: {new Date(explainData.as_of_date).toLocaleDateString()}
          </Col>
        </Row>
      )}

      <Row>
        <h4>Household Model</h4>
      </Row>
      {explainData.household_model ? (
        <PortfolioWithSecondaryRow
          accountId={""}
          accountName="TBD"
          data={explainData.household_model}
        />
      ) : (
        <Row>
          <Col>No household model</Col>
        </Row>
      )}

      <AccountDetailsDrawer accounts={explainData.accounts} />

      {explainData.category_mapping && (
        <CategoryMappingsRow categoryMapping={explainData.category_mapping} />
      )}
    </Container>
  </>
);

interface ModelCashExplainStepProps {
  explainData: {
    initial_model_cash_targets: Record<
      string,
      {
        primary: Record<string, number>;
        secondary: Record<string, Record<string, Record<string, unknown>>>;
      }
    >;
  };
  sharedData: SharedRebalanceData;
}

export const ModelCashExplainStep = ({
  explainData,
  sharedData,
}: ModelCashExplainStepProps) => (
  <>
    <Row>
      <h3>2. Model Cash</h3>
      <p>
        Adjusts weights based on cash settings. Uses account model or household
        model if account model is not available.
      </p>
    </Row>

    <Container>
      {Object.entries(explainData.initial_model_cash_targets).map(
        ([account_id, cash_target]) => {
          const accountName = sharedData.accounts[account_id]?.account_name;
          return (
            <div key={account_id}>
              <PortfolioWithSecondaryRow
                accountId={account_id}
                data={cash_target}
                accountName={accountName}
              />
            </div>
          );
        },
      )}
    </Container>

    <Row>
      <h3>3. First Draft - Taxable</h3>
      <p>
        Holdings plus positive cash show. Target portfolio after security
        restrictions applied
      </p>
    </Row>
  </>
);

interface TargetFinalExplainStepProps {
  explainData: {
    account_final_target_portfolios: Record<string, Record<string, number>>;
  };
  sharedData: SharedRebalanceData;
}

export const TargetFinalExplainStep = ({
  explainData,
  sharedData,
}: TargetFinalExplainStepProps) => (
  <>
    <Row>
      <h3>Target - Final</h3>
    </Row>

    {Object.entries(explainData.account_final_target_portfolios).reduce(
      (rows, [account_id, securities], index, array) => {
        if (index % 2 === 0) {
          rows.push(
            <Row>
              <PortfolioCol
                accountId={account_id}
                accountName={
                  sharedData.accounts[account_id]?.account_name ?? ""
                }
                data={securities}
              />
              {array[index + 1] && (
                <PortfolioCol
                  accountId={array[index + 1][0]}
                  accountName={
                    sharedData.accounts[array[index + 1][0]]?.account_name ?? ""
                  }
                  data={array[index + 1][1]}
                />
              )}
            </Row>,
          );
        }
        return rows;
      },
      [] as JSX.Element[],
    )}
  </>
);

interface TaxableAccountRebalanceStepProps {
  explainData: {
    taxable_account_rebalance_results: Record<
      string,
      {
        account_id: number;
        household_target_portfolio: Record<string, number>;
        final_trades: Record<
          string,
          {
            shares: number;
            price: number;
            realized_gain_loss_st: number;
            realized_gain_loss_lt: number;
            trade_weight: number | null;
            remaining_trade_value: number | null;
            remaining_shares_available_to_sell: number | null;
          }
        >;
        gain_loss: {
          gain_realized: number;
          loss_realized: number;
        };
        taxable_risk_category: Record<string, number>;
        taxable_asset_category: Record<string, number>;
      }
    >;
    taxable_balances: number;
    taxable_growth_amount: number;
  };
  sharedData: SharedRebalanceData;
}

export const TaxableAccountRebalanceStep = ({
  explainData,
  sharedData,
}: TaxableAccountRebalanceStepProps) => (
  <>
    <Row>
      <h4>4. Taxable Account Rebalance</h4>
    </Row>

    <Container>
      <Row className="mb-3">
        <Col>
          <h5>
            Total Taxable Balance: $
            {explainData.taxable_balances.toLocaleString()}
          </h5>
          <h5>
            Total Growth Amount: $
            {explainData.taxable_growth_amount.toLocaleString()}
          </h5>
        </Col>
      </Row>

      {Object.entries(explainData.taxable_account_rebalance_results).map(
        ([accountId, account]) => {
          const accountName = sharedData.accounts[accountId]?.account_name;
          return (
            <div key={accountId}>
              <Row>
                <Col md={6}>
                  <h5>
                    Target Portfolio {accountId && `- Account(${accountId})`}
                  </h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Target Allocation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(account.household_target_portfolio).map(
                        ([symbol, allocation]) => (
                          <tr key={symbol}>
                            <td>{symbol}</td>
                            <td>{allocation}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </Col>

                <Col md={6}>
                  <h5>Final Trades {accountId && `- Account(${accountId})`}</h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Shares</th>
                        <th>Price</th>
                        <th>ST Gain/Loss</th>
                        <th>LT Gain/Loss</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(account.final_trades).map(
                        ([symbol, trade]) => (
                          <tr key={symbol}>
                            <td>{symbol}</td>
                            <td>{trade.shares}</td>
                            <td>${trade.price.toFixed(2)}</td>
                            <td>${trade.realized_gain_loss_st.toFixed(2)}</td>
                            <td>${trade.realized_gain_loss_lt.toFixed(2)}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <h5>
                    Risk Categories
                    {accountId && (
                      <AccountLink
                        accountId={accountId}
                        accountName={accountName}
                      />
                    )}
                  </h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Allocation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(account.taxable_risk_category).map(
                        ([category, allocation]) => (
                          <tr key={category}>
                            <td>{category}</td>
                            <td>{allocation}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </Col>

                <Col md={6}>
                  <h5>
                    Asset Categories
                    {accountId && (
                      <AccountLink
                        accountId={accountId}
                        accountName={accountName}
                      />
                    )}
                  </h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Allocation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(account.taxable_asset_category).map(
                        ([category, allocation]) => (
                          <tr key={category}>
                            <td>{category}</td>
                            <td>{allocation}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row className="mt-3 mb-4">
                <Col>
                  <h5>Realized Gains/Losses</h5>
                  <div>
                    Gains Realized: $
                    {account.gain_loss.gain_realized.toFixed(2)}
                  </div>
                  <div>
                    Losses Realized: $
                    {account.gain_loss.loss_realized.toFixed(2)}
                  </div>
                </Col>
              </Row>
            </div>
          );
        },
      )}
    </Container>
    <Row>
      <h3>5. Second Draft - Non-Taxable</h3>
    </Row>
  </>
);

const MissingStep = ({
  stepName,
  explainData,
}: {
  stepName: string;
  explainData: unknown;
}) => (
  <>
    <Row>
      <h3>Unknown Step: {stepName}</h3>
    </Row>
    <Container>
      <Row>
        <Col>
          <div>Step type '{stepName}' is not implemented yet</div>
          <pre>{JSON.stringify(explainData, null, 2)}</pre>
        </Col>
      </Row>
    </Container>
  </>
);

interface FinalTaxableTargetPortfolioStepProps {
  explainData: {
    final_target_portfolio: Record<
      string,
      {
        balance: number;
        target: Record<string, number>;
        buy_total: number | null;
        sell_total: number | null;
        trades: unknown | null;
        result_portfolio: unknown | null;
      }
    >;
    total_target_portfolio: {
      balance: number;
      target: Record<string, number>;
      buy_total: number | null;
      sell_total: number | null;
      trades: unknown | null;
      result_portfolio: unknown | null;
    };
  };
}

export const FinalTaxableTargetPortfolioStep = ({
  explainData,
}: FinalTaxableTargetPortfolioStepProps) => (
  <>
    <Row>
      <h3>6. Final Taxable Target Portfolio</h3>
    </Row>

    <Container>
      <Row className="mb-3">
        <Col>
          <h5>
            Total Portfolio Balance: $
            {explainData.total_target_portfolio.balance.toLocaleString()}
          </h5>
        </Col>
      </Row>

      {Object.entries(explainData.final_target_portfolio).map(
        ([accountId, account]) => (
          <div key={accountId}>
            <Row>
              <h4>Account: {accountId}</h4>
              <h5>Balance: ${account.balance.toLocaleString()}</h5>
            </Row>

            <Row>
              <Col md={6}>
                <h5>Target Allocations</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Target Weight</th>
                      <th>Target Value(Converted)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(account.target).map(
                      ([symbol, allocation]) => (
                        <tr key={symbol}>
                          <td>{symbol}</td>
                          <td>{allocation}</td>
                          <td>
                            ${(allocation * account.balance).toLocaleString()}
                          </td>
                        </tr>
                      ),
                    )}
                    <tr className="fw-bold">
                      <td>Total</td>
                      <td>
                        {Object.values(account.target).reduce(
                          (sum, allocation) => sum + allocation,
                          0,
                        )}
                      </td>
                      <td>${account.balance.toLocaleString()}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        ),
      )}

      <Row className="mt-4">
        <h4>Total Target Portfolio</h4>
        <Col md={8}>
          <Table>
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Target Weight (Converted)</th>
                <th>Target Amount ($)</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(explainData.total_target_portfolio.target).map(
                ([symbol, amount]) => (
                  <tr key={symbol}>
                    <td>{symbol}</td>
                    <td>
                      {amount / explainData.total_target_portfolio.balance}
                    </td>
                    <td>${amount.toLocaleString()}</td>
                  </tr>
                ),
              )}
              <tr>
                <td>Total</td>
                <td>N/A</td>
                <td>
                  ${explainData.total_target_portfolio.balance.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </>
);

interface AccountDetailsDrawerProps {
  accounts: Record<string, Account>;
}

const AccountDetailsDrawer: React.FC<AccountDetailsDrawerProps> = ({
  accounts,
}) => {
  const [show, setShow] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState<Account | null>(
    null,
  );

  const handleClose = () => setShow(false);
  const handleShow = (account: Account) => {
    setSelectedAccount(account);
    setShow(true);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          right: "0px",
          top: "60%",
          transform: "translateY(-50%)",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          zIndex: 1000,
        }}
      >
        {Object.values(accounts).map((account) => (
          <Button
            key={account.account_id}
            variant="outline-primary"
            size="sm"
            style={{
              transform: "rotate(90deg)",
              display: "inline-block",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            onClick={() => handleShow(account)}
          >
            {account.settings.account_type}
          </Button>
        ))}
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Details{" "}
            {selectedAccount && (
              <AccountLink
                accountId={selectedAccount.account_id}
                accountName={selectedAccount.account_name}
              />
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selectedAccount && (
            <>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <td>Account ID</td>
                    <td>{selectedAccount.account_id}</td>
                  </tr>
                  <tr>
                    <td>Bridge Account ID</td>
                    <td>{selectedAccount.bridge_account_id}</td>
                  </tr>
                  <tr>
                    <td>Account Type</td>
                    <td>{selectedAccount.settings.account_type}</td>
                  </tr>
                  <tr>
                    <td>Tax Type</td>
                    <td>{selectedAccount.settings.tax_type}</td>
                  </tr>
                  <tr>
                    <td>Balance</td>
                    <td>
                      ${selectedAccount.balance?.toLocaleString() ?? "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Cash Balance</td>
                    <td>
                      ${selectedAccount.cash_balance?.toLocaleString() ?? "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Risk Score</td>
                    <td>{selectedAccount.risk_score ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Rebalance Type</td>
                    <td>{selectedAccount.rebalance_type}</td>
                  </tr>
                  <tr>
                    <td>Is Rebalance Account</td>
                    <td>
                      {selectedAccount.is_rebalance_account ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td>Capital Gains (Short Term)</td>
                    <td>
                      {selectedAccount.settings.gains_budget_st
                        ? selectedAccount.settings.gains_budget_st
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Capital Gains (Long Term)</td>
                    <td>
                      {selectedAccount.settings.gains_budget_lt
                        ? selectedAccount.settings.gains_budget_lt
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <h5>Cash Settings</h5>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <td>Target Cash Type</td>
                    <td>{selectedAccount.cash_settings.target_cash_type}</td>
                  </tr>
                  <tr>
                    <td>Target Cash Weight</td>
                    <td>
                      {selectedAccount.cash_settings.target_cash_weight !== null
                        ? `${selectedAccount.cash_settings.target_cash_weight}%`
                        : "—"}
                    </td>
                  </tr>
                  <tr>
                    <td>Target Cash Dollar</td>
                    <td>
                      ${selectedAccount.cash_settings.target_cash_dollar ?? "—"}
                    </td>
                  </tr>
                  <tr>
                    <td>Min Cash Settings</td>
                    <td>
                      {selectedAccount.cash_settings
                        .minimum_cash_trigger_dollar !== null
                        ? `$${selectedAccount.cash_settings.minimum_cash_trigger_dollar}`
                        : "—"}{" "}
                      {
                        selectedAccount.cash_settings
                          .minimum_cash_trigger_condition
                      }{" "}
                      {selectedAccount.cash_settings
                        .minimum_cash_trigger_weight !== null
                        ? `${selectedAccount.cash_settings.minimum_cash_trigger_weight}%`
                        : "—"}
                    </td>
                  </tr>
                  <tr>
                    <td>Max Cash Settings</td>
                    <td>
                      {selectedAccount.cash_settings
                        .maximum_cash_trigger_dollar !== null
                        ? `$${selectedAccount.cash_settings.maximum_cash_trigger_dollar}`
                        : "—"}{" "}
                      {
                        selectedAccount.cash_settings
                          .maximum_cash_trigger_condition
                      }{" "}
                      {selectedAccount.cash_settings
                        .maximum_cash_trigger_weight !== null
                        ? `${selectedAccount.cash_settings.maximum_cash_trigger_weight}%`
                        : "—"}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {selectedAccount.model && (
                <>
                  <h5>Model Portfolio</h5>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Target Allocation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(selectedAccount.model.primary).map(
                        ([symbol, allocation]) => (
                          <tr key={symbol}>
                            <td>{symbol}</td>
                            <td>{allocation}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </>
              )}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const RebalanceHouseholdExplain = () => {
  const { rebalance } = useContext(RebalanceContext);

  const {
    isPending,
    isError,
    data: explainData,
  } = useAuthenticatedFetch<
    UnpackResponse<RebalancesController["getRebalanceExplain"]>
  >(`/rebalances/${rebalance?.id}/explain`);

  if (isPending) return <Loading />;
  if (isError) return <div>Error loading rebalance explanation</div>;

  const orderedSteps = explainData?.data.toSorted(
    (a, b) => a.explainId - b.explainId,
  );

  const sharedData = {
    accounts: {},
  };

  orderedSteps?.forEach((step) => {
    switch (step.stepName) {
      case "rebalance_request":
        sharedData.accounts = step.explainData.accounts;
        break;

      default:
        break;
    }
  });

  return (
    <div className="rebalance-explain">
      {orderedSteps?.map((step) => {
        switch (step.stepName) {
          case "rebalance_request":
            return (
              <RebalanceRequestExplainStep
                key={step.explainId}
                explainData={step.explainData}
              />
            );
          case "target_portfolio":
            return (
              <TargetPortfolioRow
                key={step.explainId}
                explainData={step.explainData}
                sharedData={sharedData}
              />
            );
          case "target_risk_allocations":
            return (
              <TargetRiskAllocationRow
                key={step.explainId}
                sharedData={sharedData}
                explainData={step.explainData}
              />
            );
          case "model_cash":
            return (
              <ModelCashExplainStep
                key={step.explainId}
                explainData={step.explainData}
                sharedData={sharedData}
              />
            );
          case "target_final":
            return (
              <TargetFinalExplainStep
                key={step.explainId}
                explainData={step.explainData}
                sharedData={sharedData}
              />
            );
          case "taxable_rebalance":
            return (
              <TaxableAccountRebalanceStep
                key={step.explainId}
                sharedData={sharedData}
                explainData={step.explainData}
              />
            );
          case "final_taxable_target_portfolio":
            return (
              <FinalTaxableTargetPortfolioStep
                key={step.explainId}
                explainData={step.explainData}
              />
            );
          default:
            return (
              <MissingStep
                key={step.explainId}
                stepName={step.stepName}
                explainData={step.explainData}
              />
            );
        }
      })}
    </div>
  );
};
export default RebalanceHouseholdExplain;
